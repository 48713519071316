import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

export default function Projects({projects, title, subtitle}) {

    return (
        <section id="projects">
            <div class="space-y-12 w-full py-12 text-black dark:text-white">
                <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-6px) translateZ(0px)" }}>
                    <div class="flex flex-col items-center justify-center space-y-4 text-center">
                        <div class="space-y-2">
                            <Link class="inline-flex items-center justify-center font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-box_border dark:border-box_border_dark bg-box dark:bg-box_dark  text-foreground shadow-sm hover:bg-accent hover:text-accent-foreground h-8 px-3 text-xs rounded-full" to="/mis_proyectos">
                                {/*🎉*/}
                                🛠️
                                <div data-orientation="vertical" role="none" class="shrink-0 bg-border w-px mx-2 h-4"></div>Mis proyectos
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ml-1 h-4 w-4 text-subtitle"><path d="m9 18 6-6-6-6"></path></svg>
                            </Link>
                            <h2 class="text-3xl font-bold tracking-tighter sm:text-5xl">{title}</h2>
                            <p class="text-subtitle md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">{subtitle}</p>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-3 sm:grid-cols-2 max-w-[800px] mx-auto">
                    {
                        projects.map((project, i) => {
                            return (
                                <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-6px) translateZ(0px)" }}>
                                    <div class="cursor-pointer rounded-lg bg-box dark:bg-box_dark text-card-foreground flex flex-col overflow-hidden border hover:shadow-lg transition-all duration-300 ease-out h-full border-box_border dark:border-box_border_dark">
                                        <a class="block cursor-pointer" href={project.website}>
                                            {
                                                project.portada.type === 'video' ? (
                                                    <video src={project.portada.url} autoplay="" loop="" playsinline="" class="pointer-events-none mx-auto h-40 w-full object-cover object-top"></video>
                                                ) : (
                                                    <img class="pointer-events-none mx-auto h-40 w-full object-cover object-center" src={project.portada.url} alt={project.nombre} />
                                                )
                                            }
                                        </a>
                                        <div class="flex flex-col px-2">
                                            <div class="space-y-1">
                                                <h3 class="font-semibold tracking-tight mt-1 text-base">{project.nombre}</h3>
                                                <time class="font-sans text-xs">{project.fechas}</time>
                                                <div class="hidden font-sans text-xs underline print:visible">
                                                </div>
                                                <div class="prose max-w-full text-pretty font-sans text-xs text-subtitle dark:prose-invert">
                                                    <p>{project.descripcion}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-pretty font-sans text-sm text-subtitle mt-auto flex flex-col px-2">
                                            <div class="mt-2 flex flex-wrap gap-1">
                                                {
                                                    project.tags.map((tag, i) => {
                                                        return (
                                                            <div class="border-box_border dark:border-box_border_dark inline-flex items-center rounded-md border font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-body dark:bg-body_dark text-secondary-foreground hover:bg-secondary/80 px-1 py-0 text-[10px]">{tag}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div class="flex items-center pt-2 px-2 pb-2">
                                            <div class="flex flex-row flex-wrap items-start gap-1">
                                                <a target="_blank" href={project.website}>
                                                    <div class="items-center rounded-md border font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-body dark:bg-body_dark text-black dark:text-white shadow flex gap-2 px-2 py-1 text-[10px]">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-globe size-3">
                                                            <circle cx="12" cy="12" r="10">
                                                            </circle>
                                                            <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20">
                                                            </path>
                                                            <path d="M2 12h20">
                                                            </path>
                                                        </svg>Website</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}
