import React, { useState, useEffect } from 'react'

export default function Skills({skills, title}) {

    return (
        <section id="skills">
            <div class="flex min-h-0 flex-col gap-y-3">
                <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-6px) translateZ(0px)" }}>
                    <h2 class="text-xl font-bold text-black dark:text-white">{title}</h2>
                </div>
                <div class="flex flex-wrap gap-1">
                    {
                        skills.map((skill, i) => {
                            return (
                                
                                <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-6px) translateZ(0px)" }}>
                                    <div class="cursor-pointer inline-flex items-center rounded-md border px-2.5 md:px-4 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-box dark:bg-box_dark text-black dark:text-white shadow">
                                        <img src={skill.imagen} alt={skill.nombre} class="size-5 md:size-7 mr-1 md:p-0.5" />
                                        <p className='text-black dark:text-white'>{skill.nombre}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}
