import React, { useEffect } from "react";
import { Routes, Route, useLocation, useParams } from 'react-router-dom';

import { Inicio, PageNotFound } from "./pages";
import { Dock } from "./components";

function App() {

  useEffect(() => {
    if (localStorage.getItem('theme') === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    function detectDarkMode() {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.classList.add('dark')
        // Aquí puedes aplicar cambios, como cambiar el tema de la página
      } else {
        document.documentElement.classList.remove('dark')
      }
    }

    // Detectar modo oscuro al cargar la página
    detectDarkMode();

    // Detectar cambios en la preferencia del usuario
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      if (event.matches) {
        document.documentElement.classList.add('dark')
        // Aquí puedes aplicar cambios, como cambiar el tema de la página
      } else {
        document.documentElement.classList.remove('dark')
      }
    });

  }, [])

  return (
    <>
      <main className=" max-w-2xl mx-auto py-12 sm:py-24 px-6 flex flex-col min-h-[100dvh] space-y-10">
        <Routes>
          <Route path="" element={<Inicio />} />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/**" element={<PageNotFound />} />
        </Routes>
      </main>
      <nav className="sticky bottom-[20px]">
        <Dock />
      </nav>
    </>
  );
}

export default App;
