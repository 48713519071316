import React, { useState, useEffect } from 'react'

import { Work, Education, Skills, Projects, Cron } from '../components'
import { BlurFade } from '../ui';

import Markdown from 'react-markdown'

const BLUR_FADE_DELAY = 0.04;

const myHeaders = new Headers();
myHeaders.append("website", process.env.REACT_APP_WEBSITE_URL);

const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
};

export default function Inicio() {

    const [inicio, setInicio] = useState({ portfolio_estructura: { sections: [] } });

    useEffect(() => {

        const fetchWorkData = async (endpoint) => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + endpoint, requestOptions);
                const data = await response.json();
                return data;
            } catch (error) {
                console.error('Error fetching work data:', error);
            }
        };

        fetchWorkData('/web').then(data => {
            setInicio(data);
        })

    }, [])

    useEffect(() => {
        document.title = inicio.portfolio_name;
    }, [inicio])

    return (
        <>
            {/*<hr className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-[60px]"/>*/}
            {

                <BlurFade delay={BLUR_FADE_DELAY}>
                    <section id="hero">
                        <div class="mx-auto w-full max-w-2xl space-y-8 text-black dark:text-white">
                            <div class="gap-2 flex justify-between">
                                <div class="flex-col flex flex-1 space-y-1.5">
                                    <div class="flex">
                                        <h1 class="inline-block text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none" style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-8px) translateZ(0px)" }}>{inicio.portfolio_title}</h1>
                                    </div>
                                    <div class="flex">
                                        <span class="inline-block max-w-[600px] md:text-xl" style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-8px) translateZ(0px)" }}>{inicio.portfolio_subtitle}</span>
                                    </div>
                                </div>
                                <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-8px) translateZ(0px)" }}>
                                    <span class="relative flex shrink-0 overflow-hidden rounded-full size-28 border">
                                        <img class="object-cover h-full w-full" alt={inicio.portfolio_name} src={inicio.portfolio_image} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                </BlurFade>

            }

            {

                <BlurFade delay={BLUR_FADE_DELAY * 2}>
                    <section id="about">
                        <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-8px) translateZ(0px)" }}>
                            <h2 class="text-xl font-bold">About</h2>
                        </div>
                        <div style={{ "opacity": "1", "filter": "blur(0px)", "transform": "translateY(-8px) translateZ(0px)" }}>
                            <div class="prose max-w-full text-pretty font-sans text-sm text-subtitle dark:prose-invert markdown">
                                <Markdown>{inicio.portfolio_about}</Markdown>
                            </div>
                        </div>
                    </section>
                </BlurFade>

            }


            {

                inicio.portfolio_estructura.sections.map((section, i) => {
                    return (
                        <BlurFade delay={BLUR_FADE_DELAY * (i + 2)}>
                            {(() => {
                                switch (section.content) {
                                    case 'education':
                                        return (<Education education={inicio.education ?? []} title={section.title} />)
                                    case 'work':
                                        return (<Work work={inicio.work ?? []} title={section.title} />)
                                    case 'skills':
                                        return (<Skills skills={inicio.skills ?? []} title={section.title} />)
                                    case 'projects':
                                        return (<Projects projects={inicio.projects ?? []} title={section.title} subtitle={section.subtitle} />)
                                    case 'cron':
                                        return (<Cron cron={inicio.cron ?? []} title={section.title} subtitle={section.subtitle} />)
                                    default:
                                        return (
                                            <></>
                                        )
                                }
                            })()}
                        </BlurFade>
                    )
                })


            }




            {
                /*
                (inicio.work ?? [].length > 0) &&
                <BlurFade delay={BLUR_FADE_DELAY * 3}>
                    <Work work={inicio.work ?? []} />
                </BlurFade>
                */
            }

            {
                /*
                (inicio.education ?? [].length > 0) &&
                <BlurFade delay={BLUR_FADE_DELAY * 4}>
                    <Education education={inicio.education ?? []} />
                </BlurFade>
                */
            }

            {
                /*
                (inicio.skills ?? [].length > 0) &&
                <BlurFade delay={BLUR_FADE_DELAY * 5}>
                    <Skills skills={inicio.skills ?? []} />
                </BlurFade>
                */
            }

            {
                /*
                (inicio.projects ?? [].length > 0) &&
                <BlurFade delay={BLUR_FADE_DELAY * 6}>
                    <Projects projects={inicio.projects ?? []} />
                </BlurFade>
                */
            }

            {
                /*
                (inicio.cron ?? [].length > 0) &&
                <BlurFade delay={BLUR_FADE_DELAY * 7}>
                    <Cron cron={inicio.cron ?? []} />
                </BlurFade>
                */
            }
        </>
    )
}



